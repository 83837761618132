<template>
  <div class="force_changePassword_wrapper">
    <p class="title">{{ $t('common.button.changePw') }}</p>
    <el-form :model="ruleChangePasswordForm" :rules="rulesChangePassword" auto-complete="off"
      ref="ruleChangePasswordForm">
      <el-form-item prop="pass">
        <div class="self_label">{{ $t('changePw.field.newPw') }}</div>
        <el-input type="password" v-model="ruleChangePasswordForm.pass" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item prop="checkPass">
        <div class="self_label">{{ $t('changePw.field.confirmPw') }}</div>
        <el-input type="password" v-model="ruleChangePasswordForm.checkPass" autocomplete="off"></el-input>
      </el-form-item>
      <VerificationCode ref="verificationCode" namespace="CP_FORCE_UPDATE_PASSWORD" :token="token"
        :maskedEmail="maskedEmail" :callbackRegulator="callbackRegulator"></VerificationCode>

      <el-button class="force_btn" @click="submitForm()" data-testid="changePw">
        {{ $t('common.button.submit') }}
      </el-button>
    </el-form>
  </div>
</template>

<script>
import { md5 } from '@/util/encrypt';
import Result from '@/components/Result';
import VerificationCode from '@/components/withdraw/VerificationCode';
import { apiSendEmailVerificationCode, apiCheckPassword, apiForceUpdatePassword } from '@/resource';
import { validateCommonPassword } from '@/util/validation'

export default {
  name: 'forceChangePassword',
  components: { Result, VerificationCode },
  data() {
    const validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error(this.$t('common.formValidation.newPwReq')));
      } else {
        if (!validateCommonPassword(value)) {
          callback(new Error(this.$t('common.formValidation.passwordFormatWrong')));
        }
        else {
          callback();
        }
      }
    };
    const validateCheckPass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error(this.$t('common.formValidation.newPwReq')));
      } else if (value !== this.ruleChangePasswordForm.pass) {
        callback(new Error(this.$t('common.formValidation.confirmNotMatch')));
      }
      callback();
    };
    return {
      formFlag: true,
      ruleChangePasswordForm: {
        pass: '',
        checkPass: ''
      },
      rulesChangePassword: {
        pass: [{ validator: validatePass, trigger: ['blur', 'change'] }],
        checkPass: [{ validator: validateCheckPass, trigger: ['blur', 'change'] }]
      },
      token: null,
      callbackRegulator: null,
      maskedEmail: null
    };
  },
  mounted() {
    this.token = this.$route.params.token;
    this.callbackRegulator = this.$route.params.callbackRegulator;
    this.maskedEmail = this.$route.params.maskedEmail;
    if (!this.token) this.goToLogin();
  },
  methods: {
    submitForm() {
      const [getPasswordForm, getVerifyCodeComponent] = [
        this.$refs.ruleChangePasswordForm,
        this.$refs.verificationCode
      ];
      const [verifyPasswordMethod, verifyCodeMethod] = [
        getPasswordForm.validate(),
        getVerifyCodeComponent.submitForm()
      ];

      Promise.all([verifyPasswordMethod, verifyCodeMethod]).then(valid => {
        if (valid) {
          this.forceUpdatePassword();
        } else return false;
      });
    },
    forceUpdatePassword() {
      const getVerifyCodeComponent = this.$refs.verificationCode;

      apiForceUpdatePassword({
        pass: md5(this.ruleChangePasswordForm.pass),
        checkPass: md5(this.ruleChangePasswordForm.checkPass),
        token: this.token,
        ...getVerifyCodeComponent.getFormDataOfChild()
      }).then(resp => {
        if (resp.data.code === 0 || resp.data.code === 530) {
          this.goToLogin();
        }
      });
    },
    goToLogin() {
      return this.$router.push('/login');
    }
  }
};
</script>

<style lang="scss">
// @import '@/assets/css/pages/changePassword.scss';
.force_changePassword_wrapper {
  .title {
    font-size: 24px;
    color: #09090D;
    line-height: 36px;
    font-weight: 700;
    text-align: center;
  }

  .el-form-item:nth-child(1) {
    margin-top: 24px;
  }

  .self_label {
    font-size: 16px;
    color: $text-primary;
    line-height: 24px;
    font-weight: 700;
    margin-bottom: 8px;
    @include rtl-sass-prop-dual(text-align, left, text-align, right);

    span {
      font-weight: 400;
      color: $danger;
      @include rtl-sass-prop(margin-right, margin-left, 2px);
    }
  }

  .force_btn {
    width: 100%;
    height: 40px;
    font-size: 14px;
    font-weight: 700;
    color: $white;
    border-radius: 8px;
    background: $secondary;
    margin-top: 16px;
  }

}
</style>
